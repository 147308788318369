import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
	selector: 'pm-users-modal',
	templateUrl: './users-modal.component.html',
	styleUrls: ['./users-modal.component.scss', '../styles.scss'],
})
export class UsersModalComponent implements OnInit {

	userId: number;
	userModalTitle = 'Delete User';
	usersForm: FormGroup;
	@Input() isEdit: boolean;
	@Input() user: any;
	@Output() append: EventEmitter<User> = new EventEmitter();
	@Output() delete: EventEmitter<number> = new EventEmitter();
	saveTry: boolean;
	pen = faPen;
	plus = faPlus;

	get emailOfUser() { return this.usersForm.get('emailOfUser'); }
	get emailOfUserConfirm() { return this.usersForm.get('emailOfUserConfirm'); }
	get pIdOfUser() { return this.usersForm.get('pIdOfUser'); }

	constructor(
		private modalService: NgbModal,
		private formbuilder: FormBuilder,
		private dService: DataService,
		private vService: ValidatorService
	) { }

	ngOnInit(): void {
		this.usersForm = this.formbuilder.group({
			emailOfUser: [null, [Validators.required, Validators.email, Validators.maxLength(100), this.vService.InputChangeValidator(this.user?.email ?? "")]],
			emailOfUserConfirm: [null, [Validators.required, EmailValidator('emailOfUser')]],
			pIdOfUser: [environment.projectId],
		});
		this.userModalTitle = this.isEdit ? 'Update User' : 'Create User';
	}

	open(content: any) {
		// open modal
		this.usersForm.reset();
		this.saveTry = false;
		this.emailOfUser.setValue(this.user?.email);
		this.emailOfUserConfirm.setValue(this.user?.email);
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
	}
	close() {
		this.modalService.dismissAll();
	}
	createUser() {
		if (this.usersForm.invalid) {
			this.saveTry = true;
			return;
		}
		const newUser: User = {
			id: 0,
			email: this.emailOfUser.value,
			password: null,
			projectId: environment.projectId,
			projectName: '',
		};

		this.upsertUser(newUser);
	}

	deleteUser() {
		const targetId = this.user.id;
		if (confirm('Are you sure you want to delete?')) {
			// pass user's id to data service, to be deleted
			this.dService.deleteUser(targetId).subscribe(_ => {
				alert('The user has been deleted');
				this.delete.emit(targetId);
				this.modalService.dismissAll(UsersModalComponent);
			});
		}
	}
	saveUser() {
		if (this.usersForm.invalid) {
			this.saveTry = true;
			return;
		}
		const editUser = Object.assign({}, this.user);
		editUser.email = this.emailOfUser.value;
		this.upsertUser(editUser);
	}

	upsertUser(nUser: User) {
		this.dService.upsertUser(nUser).subscribe(newId => {
			alert(`User has been ${this.isEdit ? 'updated' : 'created'}`);
			if (this.isEdit) {
				this.user.email = this.emailOfUser.value;
			} else {
				nUser.id = newId;
				this.append.emit(nUser);
			}
			this.modalService.dismissAll(UsersModalComponent);
		});
	}
}

export function EmailValidator(confirmEmailInput: string) {
	let confirmEmailControl: FormControl;
	let emailControl: FormControl;

	return (control: FormControl) => {
		if (!control.parent) {
			return null;
		}
		if (!confirmEmailControl) {
			confirmEmailControl = control;
			emailControl = control.parent.get(confirmEmailInput) as FormControl;
			emailControl.valueChanges.subscribe(() => {
				confirmEmailControl.updateValueAndValidity();
			});
		}
		if (
			emailControl.value?.toLocaleLowerCase() !==
			confirmEmailControl.value?.toLocaleLowerCase()
		) {
			return {
				notMatch: true,
			};
		}
		return null;
	};
}
