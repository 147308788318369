import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  public InputChangeValidator(initialEmail: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === initialEmail) {
        return { valueNotChanged: true };
      }
      return null;
    };
  }
}
